import type { OptionsType } from '../types';

const pollTypeMap = {
  POTW: { _: 'potw' },
  GOT: {
    MATCHDAY: 'gotw',
    TOURNAMENT: 'gott',
    COMPETITION: 'gotc',
    ROUND: 'gotr',
    SEASON: 'gots',
    DAY: 'gotd',
  },
  TOT: {
    MATCHDAY: 'totw',
    TOURNAMENT: 'tott',
    COMPETITION: 'totc',
    ROUND: 'totr',
    SEASON: 'tots',
    DAY: 'totd',
  },
};

/**
 * Get app type from the poll type map
 *
 * @param {{ [key: string]: { [key: string]: string } }} pollTypeMap - Poll type object map
 * @param {string} [pollType] - Poll type: GOT, POTW, TOT
 * @param {string} [pollSubtype] - Poll subtype: MATCHDAY, TOURNAMENT, COMPETITION, ROUND, SEASON, DAY
 * @return {string} The type of the app: gotw, gott, gotc, gotr, gots, gotd, potw, totw, tott, totc, totr, tots, totd
 */
const getAppTypeFromMap = (
  pollTypeMap: { [key: string]: { [key: string]: string } },
  pollType?: string,
  pollSubtype?: string,
): string => {
  const upperPollType = pollType?.toUpperCase();
  const upperPollSubtype = pollSubtype?.toUpperCase();

  if (upperPollType && pollTypeMap[upperPollType]) {
    return pollTypeMap[upperPollType][upperPollSubtype] || pollTypeMap[upperPollType]['_'] || '';
  }

  return '';
};

/**
 * Get app type from the poll type map
 *
 * @param {Partial<OptionsType>} options - Options object
 * @return {string} The type of the app: gotw, gott, gotc, gotr, gots, gotd, potw, totw, tott, totc, totr, tots, totd
 */
export const getAppType = ({ appType, pollType, pollSubtype }: Partial<OptionsType>): string => {
  if (appType) return appType;

  return getAppTypeFromMap(pollTypeMap, pollType, pollSubtype);
};
