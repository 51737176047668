import { getAppType } from '../config/appType';
import { defaultOptions } from '../default-options';
import type { OptionsType } from '../types';

export const getAppOptions = (el: HTMLElement): OptionsType => {
  let result = defaultOptions;

  try {
    if (el.dataset.options) {
      const opt = JSON.parse(el.dataset.options) as OptionsType;
      result = { ...opt, appType: getAppType(opt), cup: `${opt.cup}` };
    }
  } catch (e) {
    console.error(e);
  }

  return result;
};
