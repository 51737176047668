/// <reference types="vite/client" />

import type {
  BADGE_FALLBACK,
  Competition,
  Enum,
  Match,
  Matchday,
  Player,
  Round,
  Team,
  TeamType,
  Translatable,
  Translated,
  Translation,
  Translations,
} from '@uefadigital/panenka';

export { TeamType };
export type {
  Competition,
  Enum,
  Match,
  Matchday,
  Player,
  Round,
  Team,
  Translatable,
  Translated,
  Translation,
  Translations,
};

export type StatisticsType = {
  name: string;
  translations?: Translations;
  value: string;
};

export type StatisticsDetailType = {
  playerId: string;
  statistics: StatisticsType[];
};

export type MediaAssetType = {
  id: string;
  imageUrl: string;
  images: {
    [lang: string]: string;
  };
  size?: {
    width?: string;
    height?: string;
  };
  type: string;
};
export type AssetVideo = {
  [lang: string]: MediaAssetType;
};

export type AssetImage = {
  [lang: string]: MediaAssetType;
};

export type AssetGoal = {
  minute: number;
  distance: number;
  bodyPart: string;
};
export type AssetMatchReference = {
  matchId: string;
};

export const PlayerPositions = {
  FORWARD: 'FORWARD',
  MIDFIELDER: 'MIDFIELDER',
  DEFENDER: 'DEFENDER',
  GOALKEEPER: 'GOALKEEPER',
} as const;
export type PlayerPositions = Enum<typeof PlayerPositions>;
export type AssetPlayer = Player & {
  fieldPosition?: PlayerPositions;
};

export type AssetTeam = Team;

export type Asset = {
  type: string;
  data: AssetVideo | AssetImage | AssetGoal | AssetMatchReference | AssetPlayer | AssetTeam;
};

export type NomineeType = {
  id: string;
  matchDetails: Match;
  isWinner: boolean;
  assets: Asset[];
  statistics?: StatisticsType[];
};

export enum NomineeEntityType {
  Player = 'player',
  Team = 'team',
}

export type NomineeEntity = {
  type: NomineeEntityType;
  id: string;
  name: string;
  internationalName: string;
  shortName: string;
  photo: string;
  fallback: string | BADGE_FALLBACK;
  teamName?: string; // todo: should this be used for team as well?
  badge?: string;
  badgeFallback?: BADGE_FALLBACK;
  link: string;
  // player specific
  firstName?: string;
  lastName?: string;
};

export const PollSubtypes = {
  MATCHDAY: 'MATCHDAY',
  ROUND: 'ROUND',
  TOURNAMENT: 'TOURNAMENT',
  COMPETITION: 'COMPETITION',
  SEASON: 'SEASON',
  DAY: 'DAY',
} as const;
export type PollSubtypes = Enum<typeof PollSubtypes>;

export const PollStatus = {
  UNPUBLISHED: 'UNPUBLISHED',
  UPCOMING: 'UPCOMING',
  SCHEDULED: 'SCHEDULED',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  RESULTS_AVAILABLE: 'RESULTS_AVAILABLE',
} as const;
export type PollStatus = Enum<typeof PollStatus>;

export interface PollTranslations extends Translations {
  title: Translation;
  typeDescription: Translation;
}

export interface PollType extends Translatable {
  openingDateTime: string;
  closingDateTime: string;
  status: PollStatus;
  title: string;
  nominees: NomineeType[];
  translations?: PollTranslations;
  id: string;
  winners: string[];
  subtype: PollSubtypes;
  type: string;
}

export type SponsorApiType = {
  code: string;
  competitionId: string;
  colour?: string;
  image: string;
  introText: {
    translations?: Translation;
  };
  links: Translation;
  mainSponsor: string;
  name: string;
  secondaryColour?: string;
  tags?: string[];
  type: string;
};

export type SponsorType = {
  name: string;
  color: string;
  image: string;
  introText: SponsorApiType['introText'];
  links: SponsorApiType['links'];
  title: string;
  textColor: string;
  code: string;
};

export type UserType = {
  alreadyVoted: boolean;
  userId: string;
  idProvider: string;
  pageStatus?: string;
};

export type PollResponseType = {
  data: PollType[];
};

export type ImgElem = {
  src: string;
  'emk-softCrop': string;
};

export type FigureElem = {
  img: ImgElem[];
};

export type AttributeElem = {
  href: string;
  target: string;
};

export type EditorialElem = {
  _type: string;
  type: string;
  _html: string;
  _text: string;
  figure?: FigureElem[];
  _attributes?: AttributeElem;
};
export type EditorialDataAttributesType = {
  tags?: any[];
  cpcversion?: string;
  language?: string;
  wordCount?: string;
  charCount?: string;
  firstPublicationDate?: string;
  lastPublicationDate?: string;
  visibleInLists?: boolean;
  hideFromWeb?: boolean;
  hideFromApp?: boolean;
  hideMainPhoto?: boolean;
  needsRegistration?: boolean;
  sections?: string[];
  competitions?: string[];
  workFolder?: string;
  webReference?: string;
};
export type EditorialDataType = {
  id: string;
  version: string;
  timestamp: string;
  foreignId?: string;
  title?: string;
  description?: string;
  summary?: string;
  attributes?: EditorialDataAttributesType;
  url?: string;
  resourceUrl?: string;
  picture?: string;
  lastModified?: number;
  dataType?: string;
  etag?: string;
};
export type EditorialType = {
  id: string;
  files: {
    'content.json': {
      data: {
        document: {
          headgroup: EditorialElem[];
          _elements: EditorialElem[];
          content: EditorialElem[];
          mediagroup: EditorialElem[];
        };
      };
    };
  };
  title: string;
};

export type OptionsType = {
  competitionBanner: string;
  advPosition: string;
  advSlotType: string;
  advZone: string;
  appType: string;
  winnerTag: string;
  promoTag: string;
  cup?: string;
  language: string;
  pollSubtype: string;
  pollType: string;
  season: string;
  seasonYear: string;
  videoAdvTag: string;
  appName: string;
  enableLoginFlow: boolean;
  sponsorPhase: string;
  backlinkText?: string;
  backlinkUrl?: string;
  enableHeader: boolean;
  context?: string;
};

export type MatchParamsType = {
  params?: {
    pollId?: string;
  };
};

export type EnvType = {
  [key: string]: {
    API_ENDPOINT: {
      [env: string]: string;
    };
  };
};

export type CardConfigDataType = {
  type: string;
  url: string;
};

export type CardConfigType = {
  [key: string]: {
    competition: string;
    data: CardConfigDataType[];
  };
};

export type VideoTokenType = {
  mediaAssetId: string;
  status: string;
  hlsStreamUrl: string;
};

export type PollParametersType = {
  cup: string;
  pollType: string;
  pollSubtype: string;
  seasonYear: string;
  appType: string;
};

export type LoginModalStoreType = {
  isVisible: boolean;
  counterModalViews: number;
};

export type PrizeModalStoreType = {
  isVisible: boolean;
};

export type PollStoreType = {
  currentPoll: PollType | null;
  isLoading: boolean;
  previousPolls: PollResponseType | null;
};

export type UserStoreType = {
  alreadyVoted: boolean;
  userId: string;
  idProvider: string;
  pageStatus?: string;
  username: string;
  loginType: string;
  isLogged: boolean;
};

export type VideoStoreType = {
  isPlaying: boolean;
  entityId: string;
  percentagePlayed: number;
  isFullScreen: boolean;
};

export type SponsorStoreType = {
  sponsor: SponsorType | null;
  isLoaded: boolean;
};

export type SponsorParameterType = {
  mainDirectory: string;
  cup?: string;
  sponsorPhase?: string;
};

export type PromoTracking = {
  promotion_id: string;
  promotion_name: string;
  creative_name: string;
  creative_slot: string;
};

export type CardTracking = {
  item_id?: string;
  item_list_name: string;
  item_category: string;
  item_name: string;
  item_brand?: string;
  item_variant?: string;
  index?: number;
};
