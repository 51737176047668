import { getAppType } from '../config/appType';
import { cardConfig, typeMap } from '../config/cardConfig';
import { getPollStatuses } from '../resources/pollStatuses';
import type {
  EditorialElem,
  EditorialType,
  Match,
  MediaAssetType,
  NomineeType,
  OptionsType,
  PollSubtypes,
  PollType,
  SponsorApiType,
  SponsorType,
} from '../types';

export const getCompetitionId = (item: Match) =>
  item?.competition?.id === '3' && item?.round?.phase === 'QUALIFYING' ? '17' : item?.competition?.id;

export const getVideoArticleAsset = (item: NomineeType, language = 'EN') =>
  item?.assets?.find((f) => f.type === 'VIDEO')?.data[language.toUpperCase()] as MediaAssetType;

export const removeWinner = (poll: PollType) => {
  const winnerId = poll?.winners[0];

  const nominees = poll.nominees.filter((n: NomineeType) => n.id !== winnerId);
  return { ...poll, nominees };
};

export const getWinnerNominee = (poll: PollType) => poll.nominees.find((n: NomineeType) => n.id === poll?.winners[0]);

const getByType = (arr: EditorialElem[], type: string) => arr?.find((o) => o._type === type || o.type === type);

export const mapEditorialContent = (data: EditorialType) => {
  if (!data) return null;

  const document = data.files['content.json']?.data.document;
  if (!document) return null;

  const headline = getByType(document?.headgroup, 'HEADLINE')?._html ?? data.title;
  const summary = getByType(document?._elements, 'SUMMARY')?._html;
  const body = document?.content?.map((el: EditorialElem) => el).filter((el) => el._type === 'PARAGRAPH') ?? null;
  const photos = getByType(document?.mediagroup, 'PHOTO')?.figure.at(0)?.img;
  const link = document?.content.find((elem) => elem._type === 'LINK');

  return {
    id: data.id,
    headline,
    summary,
    body,
    photos,
    link,
  };
};
export type EditorialContent = ReturnType<typeof mapEditorialContent>;

export const createRandomUUID = () => {
  try {
    return crypto.randomUUID();
  } catch {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
    );
  }
};

export const injectScript = (src: string, id: string) => {
  if (!id) id = createRandomUUID();
  return new Promise<void>((resolve, reject) => {
    let script: any = document.getElementById(id);

    if (script) return resolve();

    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = id;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

export const gptAddSize = (slotType: string, mapping: any) => {
  if (slotType === 'billboard') {
    mapping = mapping
      .addSize(
        [1024, 0],
        [
          [728, 90],
          [970, 31],
          [970, 250],
          [970, 90],
          [970, 550],
        ],
      ) //Billboard mapping
      .addSize([0, 0], []); //Show nothing mapping
  } else if (slotType === 'slider') {
    mapping = mapping
      .addSize([1024, 0], []) //Show nothing mapping
      .addSize([0, 0], [1, 1]); //Slider mapping
  } else if (slotType === 'slider-all') {
    //Slider empty mapping
  } else if (slotType === 'mpu') {
    mapping = mapping.addSize([0, 0], [300, 250]);
  } else {
    mapping = mapping
      .addSize([1024, 0], [728, 90]) //Leaderboard mapping
      .addSize([0, 0], [320, 50]); //Smartphone mapping
  }
  return mapping;
};

export const diffBetweenDateAndNow = (dateTime?: string) => {
  const date1 = Date.parse(dateTime ?? new Date().toISOString());
  const date2 = new Date();
  return +date1 - +date2;
};

export const isTeamApp = (optionsOrType: Partial<OptionsType> | string) => {
  let type: string;

  if (typeof optionsOrType === 'string') {
    type = optionsOrType;
  } else {
    type = getAppType(optionsOrType);
  }

  return type.toUpperCase().startsWith('T');
};

export const getPageSubType = (poll: PollType, directLink?: boolean) => {
  const { pollIsWinner, pollIsOpen, pollIsScheduled } = getPollStatuses(poll);

  if (directLink) return 'previouswinners';
  if (pollIsScheduled) return 'pollcountdown';
  if (pollIsOpen) return 'pollopen';
  if (pollIsWinner) return 'pollwinner';
  return 'pollclosed';
};

export const getLabel = (sponsor: SponsorType, language: string): string => {
  const lang = language.toUpperCase();
  if (sponsor?.introText?.translations?.[lang]) return sponsor.introText.translations[lang];
  if (sponsor?.introText?.translations?.['en']) return sponsor.introText.translations['en'];
  return '';
};

export const getLink = (sponsor: SponsorType, language: string) => {
  const lang = language.toUpperCase();
  if (sponsor?.links?.[lang]) return sponsor.links[lang];
  if (sponsor?.links?.['EN']) return sponsor.links['EN'];
  return '';
};

export const manipulateSponsorDataFromApi = (sponsor: SponsorApiType): SponsorType | null => {
  if (!sponsor) return null;

  return {
    name: sponsor.name,
    color: sponsor.colour ?? `sponsor--${sponsor.code}`,
    image: sponsor.image,
    links: sponsor.links,
    introText: sponsor.introText,
    title: sponsor.name,
    textColor: sponsor?.secondaryColour ?? '--pk-text-01',
    code: sponsor?.code,
  };
};

export const getSponsorFromApiResponsesAndTag: (
  resultSponsorApi: SponsorApiType[],
  tag: string,
) => SponsorApiType | undefined = (resultSponsorApi, tag) => {
  const newSponsor: SponsorApiType | undefined = resultSponsorApi?.find((s: SponsorApiType) => s.tags?.includes(tag));
  return newSponsor;
};

export const getPollSubtypeFromApi: (poll: PollType) => PollSubtypes = (poll) => poll?.subtype;

export const makeCamelCase = (str: string) => str.replace(/[-_]+(\w)/g, (_, c) => c.toUpperCase());

export const getIsMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|Tablet/i.test(navigator.userAgent);

/**
 * Define the right appName to be used in the lookup and for the tracking
 * @param appType for ex. gotw, gotd, potw etc..
 * @param subType ROUND, DAY, MATCHDAY etc..
 * @returns 'gotd, gotw, '
 */
export const getAppCode = (appType: string, subType: PollSubtypes | string) => typeMap[appType]?.[subType] ?? appType;

export const buildCardHref = (cup: string, language: string, baseUrl: string, appType: string) => {
  const appUrl = cardConfig[cup]?.data.find((n) => n.type === appType)?.url;
  if (!appUrl) return '#';

  const lang = language.replace('en', 'www');
  return baseUrl.replace('{language}', lang)?.concat(appUrl);
};
